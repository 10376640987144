<template>
  <span>
    <v-menu :close-on-content-click="true">
      <template v-slot:activator="{ on }">
        <v-btn elevation="0" icon v-on="on" class="mr-md-3">
          <v-icon size="36">mdi-plus-circle</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-if="$store.getters['auth/isStudio']"
          @click="OPEN_FORM({ action: 'save' })"
        >
          <v-list-item-icon class="mr-3 my-md-2 my-2">
            <v-icon small>mdi-plus-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("box") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="$store.getters['auth/isStudio']"
          @click="reserva({ reserva: {}, action: 'save' })"
        >
          <v-list-item-icon class="mr-3 my-md-2 my-2">
            <v-icon small>mdi-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("boxes.reservar") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="$store.getters['auth/isTattooer']"
          @click="
            $router.push({
              name: 'new_reservation_box',
              params: {
                token: studios.find((x) => x.id === studioId).token,
              },
            })
          "
        >
          <v-list-item-icon class="mr-3 my-md-2 my-2">
            <v-icon small> $request </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("boxes.reservar") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="$store.getters['auth/isStudio']"
          @click="$refs.link.dialog = true"
        >
          <v-list-item-icon class="mr-3 my-md-2 my-2">
            <v-icon small>mdi-link-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Link</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="goToCalendar()"
          v-if="$store.getters['auth/isStudio']"
        >
          <v-list-item-icon class="mr-3 my-md-2 my-2">
            <v-icon small>$calendar</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("appointments.ask") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <Form @reload="$emit('reload')" />
    <Reserva @reload="$emit('reload')" />
    <Link ref="link" />
  </span>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "AddMenu",
  methods: {
    ...mapMutations("boxes", ["OPEN_FORM", "reserva"]),
    goToCalendar() {
      this.$router.push({ name: "calendar", params: { open: true } });
    },
  },
  computed: {
    ...mapState("boxes", ["studios", "studioId"]),
  },
  components: {
    Form: () => import("@/components/boxes/components/Form"),
    Reserva: () => import("@/components/boxes/components/Reserva"),
    Link: () => import("@/components/boxes/components/Link"),
  },
};
</script>
