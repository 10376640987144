var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('v-menu',{attrs:{"close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-md-3",attrs:{"elevation":"0","icon":""}},on),[_c('v-icon',{attrs:{"size":"36"}},[_vm._v("mdi-plus-circle")])],1)]}}])},[_c('v-list',[(_vm.$store.getters['auth/isStudio'])?_c('v-list-item',{on:{"click":function($event){return _vm.OPEN_FORM({ action: 'save' })}}},[_c('v-list-item-icon',{staticClass:"mr-3 my-md-2 my-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus-circle")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("box"))+" ")])],1)],1):_vm._e(),(_vm.$store.getters['auth/isStudio'])?_c('v-list-item',{on:{"click":function($event){return _vm.reserva({ reserva: {}, action: 'save' })}}},[_c('v-list-item-icon',{staticClass:"mr-3 my-md-2 my-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("boxes.reservar"))+" ")])],1)],1):_vm._e(),(_vm.$store.getters['auth/isTattooer'])?_c('v-list-item',{on:{"click":function($event){_vm.$router.push({
            name: 'new_reservation_box',
            params: {
              token: _vm.studios.find(function (x) { return x.id === _vm.studioId; }).token,
            },
          })}}},[_c('v-list-item-icon',{staticClass:"mr-3 my-md-2 my-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" $request ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("boxes.reservar"))+" ")])],1)],1):_vm._e(),(_vm.$store.getters['auth/isStudio'])?_c('v-list-item',{on:{"click":function($event){_vm.$refs.link.dialog = true}}},[_c('v-list-item-icon',{staticClass:"mr-3 my-md-2 my-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-link-variant")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Link")])],1)],1):_vm._e(),(_vm.$store.getters['auth/isStudio'])?_c('v-list-item',{on:{"click":function($event){return _vm.goToCalendar()}}},[_c('v-list-item-icon',{staticClass:"mr-3 my-md-2 my-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$calendar")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("appointments.ask"))+" ")])],1)],1):_vm._e()],1)],1),_c('Form',{on:{"reload":function($event){return _vm.$emit('reload')}}}),_c('Reserva',{on:{"reload":function($event){return _vm.$emit('reload')}}}),_c('Link',{ref:"link"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }